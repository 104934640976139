export class WSSHClient {
  // 获取springboot服务器地址
  private _connection: WebSocket | undefined;
  getHost() {
    const wssProtocol =
      window.location.protocol === "https:" ? "wss://" : "ws://";
    //return wssProtocol + "127.0.0.1:8086" + "/api/webssh"; // js可以直接获取服务器的地址
    return wssProtocol + "y1ren.top/api/webssh";
  }

  connect(options: any) {
    const host = this.getHost();

    // 浏览器不支持websock就直接退出
    if (window.WebSocket) {
      this._connection = new WebSocket(host);
    } else {
      options.onError("WebSocket Not Supported");
      return;
    }

    this._connection.onopen = function () {
      options.onConnect();
    };

    this._connection.onmessage = function (evt) {
      const data = evt.data.toString();
      //data = base64.decode(data);
      options.onData(data);
    };

    this._connection.onclose = function () {
      options.onClose();
    };
  }

  close() {
    this._connection?.close();
  }

  // 发送json序列化数据
  send(data: any) {
    this._connection?.send(JSON.stringify(data));
  }

  // 连接参数
  sendInitData(options: any) {
    this._connection?.send(JSON.stringify(options));
  }

  // 发送指令
  sendClientData(data: any) {
    this._connection?.send(
      JSON.stringify({
        operate: "command",
        command: data,
      })
    );
  }
}
