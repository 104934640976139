import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fba11028"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  style: {
    "cursor": "pointer"
  }
};
import { ref, onMounted } from "vue";
import { MarkdownControllerService } from "../../generated";
export default {
  __name: 'EasySTL',
  setup(__props) {
    const markdown = ref("");
    const preview = ref(null);
    const res = MarkdownControllerService.getMarkdownUsingGet("projects/stl_own.md");
    res.then(resp => {
      markdown.value = resp;
    });
    const handleAnchorClick = anchor => {
      const lineIndex = anchor.lineIndex;
      const heading = preview.value.$el.querySelector(`[data-v-md-line="${lineIndex}"]`);
      if (heading) {
        /* preview.value.scrollToTarget({
                target: heading,
                scrollContainer: window,
                top: 60
            }) */

        const offset = 60;
        var elementPosition = heading.offsetTop;
        var offsetPosition = elementPosition - offset;
        document.documentElement.scrollTop = offsetPosition;
        document.body.scrollTop = offsetPosition; // For Safari
      }
    };

    const m_titles = ref([]);
    onMounted(() => {
      setTimeout(() => {
        const anchors = preview.value.$el.querySelectorAll("h1, h2, h3");
        const titles = Array.from(anchors).filter(title => !!title.innerText.trim());
        if (!titles.length) {
          m_titles.value = [];
          return;
        }
        const hTags = Array.from(new Set(titles.map(title => title.tagName))).sort();
        m_titles.value = titles.map(el => ({
          title: el.innerText,
          lineIndex: el.getAttribute("data-v-md-line"),
          indent: hTags.indexOf(el.tagName)
        }));
      }, 400);
    });
    return (_ctx, _cache) => {
      const _component_el_affix = _resolveComponent("el-affix");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_v_md_preview = _resolveComponent("v-md-preview");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_aside, {
          width: "200px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_affix, {
            offset: 120
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(m_titles.value, anchor => {
              return _openBlock(), _createElementBlock("div", {
                key: anchor.title,
                class: "title_list",
                style: _normalizeStyle({
                  padding: `10px 0 10px ${anchor.indent * 20}px`
                }),
                onClick: $event => handleAnchorClick(anchor)
              }, [_createElementVNode("a", _hoisted_2, _toDisplayString(anchor.title), 1)], 12, _hoisted_1);
            }), 128))]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createVNode(_component_v_md_preview, {
            text: markdown.value,
            ref_key: "preview",
            ref: preview,
            style: {
              "width": "80%"
            }
          }, null, 8, ["text"])]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};