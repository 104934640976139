import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin-bottom": "15px"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px",
    "text-align": "center",
    "font-size": "0.8rem",
    "overflow": "hidden",
    "text-overflow": "ellipsis"
  }
};
import { ref, watch } from "vue";
export default {
  __name: 'PictureSearch',
  props: ["picture-list"],
  setup(__props) {
    const props = __props;
    const pictureList = ref([]);
    const urlList = ref([]);
    watch(() => props.pictureList, () => {
      pictureList.value = props.pictureList;
      for (const item of pictureList.value) {
        urlList.value.push(item.url);
      }
    });
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_card = _resolveComponent("el-card");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        gutter: 12
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pictureList.value, (picture, idx) => {
          return _openBlock(), _createBlock(_component_el_col, {
            key: picture.url,
            span: 4.3
          }, {
            default: _withCtx(() => [_createVNode(_component_el_card, {
              style: {
                "width": "200px",
                "height": "210px",
                "margin-right": "10px"
              },
              "body-style": {
                padding: '0px'
              },
              shadow: "hover"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_image, {
                style: {
                  "height": "150px"
                },
                src: picture.url,
                "zoom-rate": 1.2,
                "preview-src-list": urlList.value,
                "initial-index": idx,
                fit: "fill"
              }, null, 8, ["src", "preview-src-list", "initial-index"]), _createElementVNode("div", _hoisted_2, _toDisplayString(picture.title), 1)]),
              _: 2
            }, 1024)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]);
    };
  }
};