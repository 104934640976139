import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import "@/access";
// 引入你所使用的主题 此处以 github 主题为例
import githubTheme from "@kangc/v-md-editor/lib/theme/github";
import "@kangc/v-md-editor/lib/theme/style/github.css";
import ArcoVue from "@arco-design/web-vue";
import "@arco-design/web-vue/dist/arco.css";
import "bytemd/dist/index.css";
import createKatexPlugin from "@kangc/v-md-editor/lib/plugins/katex/cdn.js";

import hljs from "highlight.js";

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

VMdPreview.use(createKatexPlugin());

createApp(App)
  .use(store)
  .use(router)
  .use(ArcoVue)
  .use(ElementPlus, { locale: zhCn })
  .use(VMdPreview)
  .mount("#app");
