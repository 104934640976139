import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
import router from "@/router";
import { ref, watch } from "vue";
export default {
  __name: 'PostSearch',
  props: ["post-list"],
  setup(__props) {
    const props = __props;
    const postList = ref([]);
    watch(() => props.postList, () => {
      postList.value = props.postList;
    });
    const postDetail = path => {
      const href = router.resolve({
        path: "/blogs/" + path
      });
      window.open(href.href, "_blank");
    };
    return (_ctx, _cache) => {
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_v_md_preview = _resolveComponent("v-md-preview");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(postList.value, post => {
        return _openBlock(), _createBlock(_component_el_card, {
          class: "box-card",
          key: post.id,
          "body-style": {
            'max-height': '300px'
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(post.tagList, tag => {
            return _openBlock(), _createBlock(_component_el_tag, {
              key: tag,
              class: "ml-2",
              type: "info",
              style: {
                "margin-right": "5px"
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(tag), 1)]),
              _: 2
            }, 1024);
          }), 128)), _createVNode(_component_v_md_preview, {
            text: post.content,
            style: {
              "max-height": "200px",
              "cursor": "pointer"
            },
            onClick: $event => postDetail(post.id)
          }, null, 8, ["text", "onClick"])]),
          _: 2
        }, 1024);
      }), 128);
    };
  }
};