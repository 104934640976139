import ACCESS_ENUM from "@/access/accessEnum";
import store from "@/store";
import router from "@/router";
import checkAccess from "@/access/checkAccess";

router.beforeEach(async (to, from, next) => {
  const needAccess: string =
    (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;
  if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
    let loginUser = store.state.user.loginUser;
    if (!loginUser || !loginUser.userRole) {
      await store.dispatch("getLoginUser");
      loginUser = store.state.user.loginUser;
    }
    if (
      !loginUser ||
      !loginUser.userRole ||
      loginUser.userRole === ACCESS_ENUM.NOT_LOGIN
    ) {
      next(`/user/login?redirect=${to.fullPath}`);
      return;
    }

    if (!checkAccess(loginUser, needAccess)) {
      next("/404");
      return;
    }
  }

  next();
});
