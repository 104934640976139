import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4dd59c92"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "index-page"
};
const _hoisted_2 = {
  class: "search-box"
};
const _hoisted_3 = {
  style: {
    "padding-bottom": "20px",
    "float": "right"
  }
};
import { ref, watchEffect } from "vue";
import PostSearch from "../components/PostSearch.vue";
import PictureSearch from "../components/PictureSearch.vue";
import router from "@/router";
import { useRoute } from "vue-router";
import axios from "axios";
import { SearchControllerService } from "../../generated";
export default {
  __name: 'AggregationSearch',
  setup(__props) {
    const route = useRoute();
    const searchText = ref(route.query.text || "");
    const activeKey = route.params.searchCategory;
    const currentPage = ref(parseInt(route.query.current) || 1);
    const loading = ref(true);
    const initSearchParams = {
      type: activeKey,
      text: "",
      current: currentPage.value
    };
    const searchParams = ref(initSearchParams);
    const postList = ref([]);
    const pictureList = ref([]);
    const total = ref(0);
    const pageSize = ref(10);
    const loadData = params => {
      const {
        type = "post"
      } = params;
      if (params.type === "picture") {
        pageSize.value = 40;
      } else {
        pageSize.value = 10;
      }
      const query = {
        ...params,
        searchText: params.text,
        pageSize: pageSize.value
      };
      const res = SearchControllerService.searchAllUsingPost(query);
      res.then(resp => {
        const data = resp.data;
        total.value = parseInt(data.total);
        const dataList = data.dataList;
        if (type == "post") postList.value = dataList;else if (type == "picture") pictureList.value = dataList;
        loading.value = false;
      });
    };
    watchEffect(() => {
      searchParams.value = {
        ...initSearchParams,
        text: route.query.text,
        type: route.params.searchCategory,
        current: route.query.current
      };
      currentPage.value = parseInt(searchParams.value.current) || 1;
      if (route.path.startsWith("/aggregation-search")) loadData(searchParams.value);
    });
    const onSearch = () => {
      router.push({
        query: {
          ...searchParams.value,
          text: searchText.value
        }
      });
    };
    const onTabChange = key => {
      router.push({
        path: `/aggregation-search/${key}`,
        query: searchParams.value
      });
    };
    const pageChange = page => {
      router.push({
        query: {
          ...searchParams.value,
          current: page
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_a_pagination = _resolveComponent("a-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_input, {
        modelValue: searchText.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchText.value = $event),
        placeholder: "请输入搜索关键词",
        size: "large"
      }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
        type: "primary",
        size: "large",
        onClick: onSearch
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createElementVNode("div", null, [_createVNode(_component_el_tabs, {
        modelValue: _unref(activeKey),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(activeKey) ? activeKey.value = $event : null),
        onTabChange: onTabChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "文章",
          name: "post"
        }, {
          default: _withCtx(() => [_createVNode(PostSearch, {
            "post-list": postList.value
          }, null, 8, ["post-list"])]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "图片",
          name: "picture"
        }, {
          default: _withCtx(() => [_createVNode(PictureSearch, {
            "picture-list": pictureList.value
          }, null, 8, ["picture-list"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_a_pagination, {
        total: total.value,
        current: currentPage.value,
        "page-size": pageSize.value,
        onChange: pageChange
      }, null, 8, ["total", "current", "page-size"])])]);
    };
  }
};