import TerminalPage from "@/views/TerminalPage.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import UserLoginView from "@/views/user/UserLoginView.vue";
import accessEnum from "@/access/accessEnum";
import AggregationSearch from "@/views/AggregationSearch.vue";
import DispatchCommand from "@/views/DispatchCommand.vue";
import FileSystem from "@/views/FileSystem.vue";
import JsonParse from "@/views/JsonParse.vue";
import EasySTL from "@/views/EasySTL.vue";
import SkipList from "@/views/SkipList.vue";
import RankAlgorithm from "@/views/algorithms/RankAlgorithm.vue";
import BaseAlgorithm from "@/views/algorithms/BaseAlgorithm.vue";
import DataStructure from "@/views/algorithms/DataStructure.vue";
import BlogVue from "@/views/Blogs/BlogVue.vue";
import NotFound from "@/components/NotFound.vue";
import { RouteRecordRaw } from "vue-router";
import AdminLayout from "@/layouts/AdminLayout.vue";
import AdminPostList from "@/views/admin/AdminPostList.vue";
import AdminPostAdd from "@/views/admin/AdminPostAdd.vue";
import SearchAlgorithm from "@/views/algorithms/SearchAlgorithm.vue";
import GraphTheory from "@/views/algorithms/GraphTheory.vue";
import DP_Greedy from "@/views/algorithms/DP_Greedy.vue";
import FlamingoSystem from "@/views/FlamingoSystem.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    name: "管理界面",
    component: AdminLayout,
    children: [
      {
        path: "/admin",
        // name: "文章列表",
        // component: AdminPostList,
        redirect: {
          path: "/admin/list/post",
        },
        // meta: {
        //   hideInMenu: true,
        //   access: accessEnum.ADMIN,
        // },
      },
      {
        path: "/admin/list/post",
        name: "文章列表",
        component: AdminPostList,
        meta: {
          access: accessEnum.ADMIN,
        },
      },
      {
        path: "/admin/add/post",
        name: "新增文章",
        component: AdminPostAdd,
        meta: {
          access: accessEnum.ADMIN,
        },
      },
      {
        path: "/admin/update/post",
        name: "更新文章",
        component: AdminPostAdd,
        meta: {
          hideInMenu: true,
          access: accessEnum.ADMIN,
        },
      },
    ],
  },
  {
    path: "/",
    name: "home-page",
    component: TerminalPage,
  },
  {
    path: "/terminal",
    name: "terminal-page",
    component: TerminalPage,
  },
  {
    path: "/user",
    name: "用户",
    component: UserLayout,
    children: [
      {
        path: "/user/login",
        name: "用户登录",
        component: UserLoginView,
      },
    ],
  },
  {
    path: "/aggregation-search/:searchCategory",
    name: "aggregation-search",
    component: AggregationSearch,
  },
  {
    path: "/dispatch-command",
    name: "dispatch-command",
    component: DispatchCommand,
  },
  {
    path: "/file-system",
    name: "file-system",
    component: FileSystem,
  },
  {
    path: "/json-parse",
    name: "json-parse",
    component: JsonParse,
  },
  {
    path: "/stl-easy",
    name: "stl-easy",
    component: EasySTL,
  },
  {
    path: "/skip-list",
    name: "skip-list",
    component: SkipList,
  },
  {
    path: "/flamingo-system",
    name: "flamingo-system",
    component: FlamingoSystem,
  },
  {
    path: "/rank-algorithm",
    name: "rank-algorithm",
    component: RankAlgorithm,
  },
  {
    path: "/base-algorithm",
    name: "base-algorithm",
    component: BaseAlgorithm,
  },
  {
    path: "/data-structure",
    name: "data-structure",
    component: DataStructure,
  },
  {
    path: "/search-algorithm",
    name: "search-algorithm",
    component: SearchAlgorithm,
  },
  {
    path: "/graph-theory",
    name: "graph-theory",
    component: GraphTheory,
  },
  {
    path: "/dp-greedy",
    name: "dp-greedy",
    component: DP_Greedy,
  },
  {
    // path: "/blogs/:blogname",
    path: "/blogs/:id",
    props: true,
    component: BlogVue,
  },
  {
    path: "/blogs/:dict/:blogname",
    component: BlogVue,
  },
  {
    path: "/404/",
    name: "404",
    component: NotFound,
    meta: {
      requestAuth: false,
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/",
  },
];
